import React from 'react';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';

const PlusIcon = () => {
  const { themeName } = useThemeContext();
  const isExpThemeFromContext = themeName && themeName !== ThemeNames.ZOOCASA;

  return (
    <>
      { !(isExpThemeFromContext) ? (
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="PVR-Form-Open" transform="translate(-22.000000, -330.000000)" fill="#000000" fillRule="nonzero">
              <g id="Group-11">
                <g id="021-plus" transform="translate(22.000000, 330.000000)">
                  <path d="M15.375,7.375 L8.625,7.375 L8.625,0.625 C8.625,0.2798125 8.3451875,0 8,0 C7.6548125,0 7.375,0.2798125 7.375,0.625 L7.375,7.375 L0.625,7.375 C0.2798125,7.375 0,7.6548125 0,8 C0,8.3451875 0.2798125,8.625 0.625,8.625 L7.375,8.625 L7.375,15.375 C7.375,15.7201875 7.6548125,16 8,16 C8.3451875,16 8.625,15.7201875 8.625,15.375 L8.625,8.625 L15.375,8.625 C15.7201875,8.625 16,8.3451875 16,8 C16,7.6548125 15.7201875,7.375 15.375,7.375 Z" id="Path"></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clipPath="url(#clip0_1244_7393)">
            <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#0C0F24"/>
          </g>
          <defs>
            <clipPath id="clip0_1244_7393">
              <rect width="24" height="24" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};

export default PlusIcon;
